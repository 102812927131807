export const recruiterPhoto = recruiterName => {
  const photos = {
    ["Alice Baculáková"]: "/assets/recruiters/AliceBaculakova.jpeg",
    ["Sandra Jirásková"]: "/assets/recruiters/SandraJiraskova.png",
    ["Nikol Sklenářiková"]: "/assets/recruiters/NikolSklenarikova.jpeg",
    ["Tereza Koštůrová"]: "/assets/recruiters/Tereza-Majkova.jpeg",
    ["Klára Schilhartová"]: "/assets/KlaraSchilhartova.png",
    ["Lan Anh Tran Ba"]: "/assets/recruiters/Lan.jpeg",
    ["Tereza Pospíšilová"]: "/assets/recruiters/TerezaPospisilova.jpeg",
    ["Petra Černohorská"]: "/assets/recruiters/PetraCernohorska.jpeg",
    ["Klára Fittnerová"]: "/assets/recruiters/KlaraFittnerova.jpeg",
    ["Karolína Alexandrová"]: "/assets/recruiters/KarolinaAlexandrova.png",
    ["Petra Koubová"]: "/assets/recruiters/PetraKoubova.jpg",
    ["Lenka Tardíková"]: "/assets/recruiters/LenkaTardikova.jpeg",
  };

  if (photos[recruiterName]) {
    return photos[recruiterName];
  }
  return "/assets/recruiters/default.png";
};

export const departmentPhoto = departmentName => {
  const photos = {
    ["Strategie a transakce"]: "/assets/departments/transakce.jpg",
    ["Audit"]: "/assets/departments/audit.jpg",
    ["Business Consulting"]: "/assets/departments/advisory.jpg",
    ["Daně"]: "/assets/departments/dane.jpg",
    ["Forenzní služby"]: "/assets/departments/forenzni_sluzby.jpg",
    ["Technology Consulting"]: "/assets/departments/it_consulting.jpg",
    ["Právo"]: "/assets/departments/pravo.png",
  };

  if (photos[departmentName]) {
    return photos[departmentName];
  }
  return "";
};
